import Vue from "vue";
import CommonDataModule from "@/store/modules/commonDataModule";
import { Servicio } from "apd.sistemapagos.models";

export default Vue.extend({
  name: "PermisosComponent",
  props: {
    cajero: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      name: "",
    };
  },
  computed: {
    serviciosList(): Servicio[] {
      return CommonDataModule.Servicios;
    },
  },

  async created() {
    const tasks: Promise<void>[] = [];
    if (this.serviciosList.length <= 1) {
      tasks.push(CommonDataModule.LoadServicios());
    }
    if (tasks.length > 0) {
      await Promise.all(tasks);
    }
  },

  methods: {
    servSelected(idServ: string) {
      return CommonDataModule.Servicios.find((e) => e.id === idServ)?.nombrePublico;
    },
  },
});
